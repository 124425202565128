<template>
  <v-container fluid>
    <v-data-table
      :headers="headers[$vuetify.breakpoint.xsOnly]"
      :items="items"
      :items-per-page="10"
      item-key="code"
      sort-by="issue_date"
      mobile-breakpoint="10"
      :search="search"
      :loading="loading_status"
      loader-height="10"
      loading-text="Cargando ..."
      @click:row="handleClick"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Clients</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="mx-2" fab dark small color="success" to="ClientsMap">
            <v-icon dark> mdi-map-marker-radius-outline </v-icon>
          </v-btn>
          <v-btn class="mx-2" fab dark small color="#1c2e5a" @click="newForm">
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-toolbar>
        <v-text-field v-model="search" label="Search" class="mx-4" />
      </template>
      <template v-slot:[`item.picture`]="{ item }">
        <v-list-item-avatar>
          <v-img contain :src="getImagePhoto(item.account, item.picture)"></v-img>
        </v-list-item-avatar>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
        <span v-if="$vuetify.breakpoint.xsOnly">
          <br />
          <small>{{ item.issue_date }}</small>
        </span>
      </template>
      <template v-slot:[`item.note`]="{ item }">
        <span v-html="item.note"></span>
      </template>
      <template v-slot:[`item.city`]="{ item }">
        <span v-if="!$vuetify.breakpoint.xsOnly">
          {{ item.city }}
        </span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="handleClick(item)">
          mdi-account-edit-outline
        </v-icon>

        <v-icon small class="mr-2" @click="newOrder(item)">
          mdi-file-document-plus-outline
        </v-icon>
      </template>
    </v-data-table>

    <PartyForm
      :table="table"
      :item="item"
      :dialog="dialog"
      :newItem="newItem"
      @close="dialog = false"
      @refresh="refresh()"
      @newOrder="newOrder(item)"
    />

    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando ...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver, getIssueDate, getToday } from "../../services/webserver.js";
import createItem from "../../utils/createParty.js";
import PartyForm from "../../components/PartyForm.vue";

export default {
  components: { PartyForm },
  mounted() {
    this.get_items();
  },
  data() {
    return {
      chosenPhoto: null,
      table: "clients",
      validMsg: false,
      broadcast: { email: null, subject: null, msg: null },
      broadcast_dialog: false,
      customToolbar: [
        [{ font: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ header: 1 }, { header: 2 }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],
      dialog: false,
      newItem: false,
      search: "",
      loading_status: false,
      errors: [],
      items: [],
      item: createItem(),
      headers: {
        false: [
          {
            text: "",
            align: "start",
            sortable: true,
            value: "picture",
          },
          {
            text: "Name",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "Address",
            align: "start",
            sortable: true,
            value: "address",
            dataType: "text",
          },
          {
            text: "Unit/Office/Suit",
            align: "start",
            sortable: true,
            value: "address2",
            dataType: "text",
          },
          {
            text: "City",
            align: "start",
            sortable: true,
            value: "city",
            dataType: "text",
          },
          {
            text: "Note",
            align: "start",
            sortable: true,
            value: "note",
            dataType: "text",
            width: "30%",
          },
          {
            text: "Issue Date",
            align: "start",
            sortable: true,
            value: "issue_date",
            dataType: "text",
          },
          {
            text: "Mobile",
            align: "end",
            sortable: true,
            value: "mobile",
            dataType: "text",
          },
        ],
        true: [
          {
            text: "Name",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "",
            align: "start",
            sortable: true,
            value: "city",
            dataType: "text",
          },
          {
            text: "Mobile",
            align: "end",
            sortable: true,
            value: "mobile",
            dataType: "text",
          },
        ],
      },
      issueDateFrom: getIssueDate(5),
      issueDateTo: getToday(),
      groupby: "",
      groups: [],
    };
  },
  methods: {
    PhotoToBase64() {
      if (!this.chosenPhoto) {
        this.data = "No File Chosen";
      }

      this.getBase64(this.chosenPhoto).then((data) => {
        var qry = {
          account: this.$store.state.profile.account,
          banner: data,
          filename: this.chosenPhoto.name,
        };
        console.log(qry);
        webserver("upload_banner", qry, (data) => {
          this.loading_status = false;
          this.broadcast.msg =
            "<img src='https://mayorista.app/banners/" + data + "'></img>";
          console.log(data);
        });
        //this.broadcast.msg = "<img src='" + data + "'></img>";
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    newOrder(e) {
      this.$router.push({ name: "Inicio", params: e });
    },
    handleClick(data) {
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    updateLocation(e) {
      this.store.location = JSON.stringify(e);
    },
    changeClients() {
      this.get_items();
    },
    get_items() {
      var qry = {
        account: this.$store.state.profile.account,
        table: this.table,
        filters: [
          { field: "account", operator: "=", value: this.$store.state.profile.account },
        ],
      };

      console.log(qry);
      this.loading_status = true;
      webserver("get_table_b", qry, (data) => {
        this.loading_status = false;
        this.items = data;
      });
    },
    refresh() {
      this.dialog = false;
      this.get_items();
    },
    getImagePhoto: function (account, src) {
      var url =
        "https://" +
        this.$store.getters.getHost +
        "/photos/" +
        account +
        "/" +
        this.table +
        "/" +
        src;
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    newForm() {
      this.item = createItem();
      this.item.account = this.$store.state.profile.account;
      this.valid = false;
      this.newItem = true;
      this.dialog = true;
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
